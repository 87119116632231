export default [
  {
    title: 'Streams',
    header: 'Streams',
    icon: 'AirplayIcon',
    children: [
      {
        title: 'Audio Broadcast',
        route: 'AudioStream',
        icon: 'MusicIcon',
        permKeys: ['audioStream']
      },
      {
        title: 'Video Stream',
        route: 'VideoStream',
        icon: 'FilmIcon',
        permKeys: ['videoStram']
        
      },
    ]
  },
  {
    title: 'Programs',
    route: 'Shows',
    icon: "ListIcon",
    permKeys: ['show']
  },
  {
    title: 'Presenters',
    route: 'Presenters',
    icon: "MicIcon",
    permKeys: ['announcer']
  },
  // {
  //   title: 'News',
  //   route: 'News',
  //   icon: 'GridIcon',
  //   permKeys: ['category']
  // },
  // {
  //   title: 'News Category',
  //   route: 'Categories',
  //   permKeys: ['category']
  // },
  {
    title: 'Gallery',
    route: 'Media',
    icon: 'ImageIcon',
    permKeys: ['media']
  },
  {
    title: 'Files',
    route: 'Files',
    icon: 'FolderIcon',
    permKeys: ['files']
  },
  
  {
    title: 'Users',
    route: 'Accounts',
    icon: 'UserIcon',
    permKeys: ['accounts', 'settings']
  },
  // {
  //   title: 'Roles',
  //   route: 'Roles',
  //   icon: 'LockIcon',
  //   permKeys: ['roles']
  // },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon',
    permKeys: ['settings']
  },
 
];
