<template>

  <div v-if="!isLoading">
    <DaysRepeater 
      :editObj='formObj' 
      :getRepeaterData='getRepeaterData' 
      ref='daysRepeaterForm'
    />
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DaysRepeater from './DaysRepeater.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    DaysRepeater,
    ToastificationContent
  },
  data() {
    return {
      isLoading: true,
      formObj: {},
      formData: {},
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    this.$store.commit('setModalLoading', true);
    this.formObj = {
      ...this.editObj,
    };

    this.isLoading = false;
    this.$store.commit('setModalLoading', false);
  },
  methods: {
      ...mapActions(['createShow', 'getShows']),
    formSubmit() {
      this.$store.commit('setModalLoading', true);
      this.$refs.daysRepeaterForm.submit()
        .then(res =>  {
          if(res) {
            this.createShow(this.formData)
              .then(showRes => {
                this.closeModal();
                this.$store.commit('setModalLoading', false);
                this.getShows({all_locale: '1', limitedBy: 100});
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Times Updated Successfully",
                    icon: 'XIcon',
                    variant: 'success',
                  },
                });
              }).catch(showErr => {
                this.$store.commit('setModalLoading', false);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t(err.response.data.results.message),
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                });
              })
          }
        })
    },
    getRepeaterData(data) {
      this.formData.times = data.items;
      this.formData.deleted_times = data.deleted_times;
      this.formData.id = this.formObj.id;
    },
  },
  computed: {
    // ...mapGetters(['modalCustomOpts']),
    // restFields() {
    //   return this.modalCustomOpts.formFields;
    // }
  }
}

</script>